<style lang="scss" scoped>
.container {
    background-color: #f8f8f8;
}

.form-inline {
    padding-top: 10px;
    background-color: #fff;
    padding-left: 16px;
    margin-bottom: 10px;
}

.pagination-container {
    text-align: right;
    margin-top: 10px;
}
</style>
<template>
    <el-main class="com-eTrip-section">
        <div class="eTrip-section-cont container">
            <el-form :inline="true" size="small" :model="form" class="form-inline">
                <el-form-item label="">
                    <el-autocomplete
                        class="inline-input"
                        v-model="operatorName"
                        :fetch-suggestions="querySearchOperator"
                        placeholder="运营商"
                        clearable
                        @select="handleOperatorChange"
                    ></el-autocomplete>
                </el-form-item>
                <el-form-item label="">
                    <el-autocomplete
                        style="width: 240px;"
                        class="inline-input"
                        v-model="supplierName"
                        clearable
                        value-key="supplierName"
                        :fetch-suggestions="querySearchMerchant"
                        placeholder="绑定机构"
                        @select="handleMerchantChange"
                    ></el-autocomplete>
                </el-form-item>
                <el-form-item label="支付日期">
                    <el-date-picker
                        v-model="form.dates"
                        align="right"
                        type="daterange"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="截止日期：">
                    <el-select style="width: 120px;" v-model="form.validityEndTime" placeholder="截止日期">
                        <el-option label="全部" value=""></el-option>
                        <el-option v-for="(vm,idx) in validityEndTypes" :label="vm.title" :value="vm.value"
                                   :key="idx"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="充值类型：">
                    <el-select style="width: 80px;" v-model="form.rechargeType" placeholder="充值类型">
                        <el-option label="全部" value=""></el-option>
                        <el-option v-for="(vm,idx) in rechargeTypes" :label="vm" :value="idx" :key="idx"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="服务类型：">
                    <el-select style="width: 120px;" v-model="form.productType" placeholder="服务类型">
                        <el-option label="全部" value=""></el-option>
                        <el-option v-for="(vm,idx) in rechargeProductTypes" :label="vm.label" :value="vm.value"
                                   :key="idx"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="">
                    <el-input style="width: 120px;" clearable v-model="form.driverName" placeholder="姓名"></el-input>
                </el-form-item>
                <el-form-item label="">
                    <el-input v-model="form.mobile" clearable type="tel" maxlength="11" placeholder="手机号"></el-input>
                </el-form-item>
                <el-form-item label="">
                    <el-input v-model="form.carNumberPlate" clearable placeholder="车牌号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handelSearch">搜索</el-button>
                    <el-button type="success" @click="handelExport" v-if="userInfo.userType==0">导出</el-button>
                </el-form-item>
            </el-form>
            <el-table
                :data="list"
                style="width: 100%">
                <el-table-column
                    prop="createTime"
                    label="充值时间">
                    <template slot-scope="scope">
                        {{ scope.row.createTime | dateCus('yyyy-MM-dd hh:mm') }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="rechargeRecordNo"
                    label="交易单号">
                </el-table-column>
                <el-table-column
                    label="支付方式">
                    <template slot-scope="scope">
                        {{ scope.row.payTypeName }}
                    </template>
                    微信
                </el-table-column>
                <el-table-column
                    prop="transactionId"
                    width="100px"
                    label="支付流水号">
                </el-table-column>
                <el-table-column
                    prop="carNumberPlate"
                    label="车牌号">
                </el-table-column>
                <el-table-column label="车型">
                    <template slot-scope="scope">
                        <span>{{ formatCarModel(scope.row.carType).name }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="driverName"
                    label="姓名/手机号">
                    <template v-slot="scope">
                        <p>{{ scope.row.driverName }}</p>
                        <p>{{ scope.row.mobile }}</p>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="operatorName"
                    label="所属运营商">
                </el-table-column>
                <el-table-column
                    prop="supplierName"
                    label="所属供应商">
                </el-table-column>
                <el-table-column label="服务类型">
                    <template v-slot="scope">
                        <span>{{ scope.row.productType | formatDesc(rechargeProductTypes) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="充值类型">
                    <template slot-scope="scope">
                        <span>{{ rechargeTypes[scope.row.rechargeType] }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="充值渠道">
                    <template slot-scope="scope">
                        <span v-if="scope.row.payWay === 0">微信充值</span>
                        <span v-if="scope.row.payWay === 1">BMS</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="rechargePrice"
                    label="充值金额">
                    <template v-slot="scope">
                        ¥{{ scope.row.rechargePrice | currency }}
                    </template>
                </el-table-column>
                <el-table-column label="有效期起">
                    <template slot-scope="scope">
                        <p>{{ scope.row.validityStartTime | dateCus('yyyy-MM-dd hh:mm') }}</p>
                    </template>
                </el-table-column>
                <el-table-column label="有效期止">
                    <template slot-scope="scope">
                        <p>{{ scope.row.validityEndTime | dateCus('yyyy-MM-dd hh:mm') }}</p>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination-container">
                <el-pagination
                    layout="prev, pager, next"
                    :total="total"
                    @current-change="handleCurrentChange"
                >
                </el-pagination>
            </div>
        </div>
    </el-main>
</template>

<script type="text/ecmascript-6">
import {getSeatModel, rechargeProductTypes} from "@/data/index";
import {getPayTypeLabel} from "../../vehicleService/components/data"
import moment from 'moment'

export default {
    //定义模版数据
    data() {
        let nowDate = +new Date();
        let time = 24 * 60 * 60 * 1000;
        return {
            rechargeProductTypes,
            rechargeTypes: ['包月', '包季', '包年', '包日'],
            validityEndTypes: [{
                title: '一周内',
                value: +nowDate + time * 7
            }, {
                title: '两周内',
                value: +nowDate + time * 14
            },
                {
                    title: '一月内',
                    value: +nowDate + time * 30
                }],
            supplierName: null,
            selectedSupplier: {},
            selectedOperator: {},
            operatorName: null,
            list: [],
            total: 0,
            form: {
                productType: '',
                dates: [],
                driverName: '',
                rechargeType: '',
                mobile: null,
                pageIndex: 1,
                pageSize: 10,
                createTime: null,
                validityEndTime: null
            }
        }
    },
    //计算属性
    computed: {
        userInfo() {
            return this.$store.state.loginUserInfo;
        }
    },
    components: {},
    //主件被加载完成
    mounted: function () {
        this.getOperatorInfoList();
        this.getData();
    },
    //定义事件方法
    methods: {
        //获取运营商列表
        async getOperatorInfoList() {
            const ret = await this.http('/galaxyOperator/operatorInfoList', null, 'POST');
            if (ret.success && ret.data) {
                this.operatorList = ret.data;
                this.operatorList = this.operatorList.map((item) => {
                    item.value = item.operatorName;
                    return item
                })
            } else
                this.$message.error(ret.errors[0].message)
        },
        createFilter(itemString) {
            return (restaurant) => {
                return (restaurant.value.indexOf(itemString) === 0);
            };
        },
        //模糊查询运营商
        querySearchOperator(operatorString, cb) {
            let operatorList = this.operatorList;
            let operatorRes = operatorString ? operatorList.filter(this.createFilter(operatorString)) : operatorList;
            cb(operatorRes);
        },
        //模糊查询机构列表
        async querySearchMerchant(operatorString, cb) {
            if (!operatorString)
                return cb([])
            const ret = await this.http('/galaxySupplier/supplierInfoPageList', {
                companyName: operatorString
            }, 'POST');
            if (ret.success && ret.data) {
                cb(ret.data.data)
            } else cb([])
        },
        //用户选择运营商
        handleOperatorChange(data) {
            this.selectedOperator = data;
        },
        //用户选择绑定机构
        handleMerchantChange(data) {
            this.selectedSupplier = data
        },
        //点击搜索
        handelSearch() {
            this.form.pageIndex = 1;
            this.getData();
        },
        //提交搜索
        async getData() {
            this.loadingShow()
            let supplierId = null,
                operatorId = null;
            if (this.selectedOperator.operatorName === this.operatorName)
                operatorId = this.selectedOperator.id
            if (this.selectedSupplier.supplierName === this.supplierName)
                supplierId = this.selectedSupplier.id
            let form = {
                ...this.form,
                operatorId,
                supplierId,
                createTime: this.form.createTime ? +new Date(this.form.createTime) : null,
                productType: this.form.productType || undefined,
                rechargeType: this.form.rechargeType || undefined,
                carNumberPlate: this.form.carNumberPlate || undefined,
            }
            if (form.dates.length > 0) {
                form.startCreateTime = moment(form.dates[0]).valueOf();
                form.endCreateTime = moment(form.dates[1]).endOf('d').valueOf();
            }
            delete form.dates;
            if (!form.driverName)
                delete form.driverName;
            const ret = await this.http('/galaxyRechargeRecordApi/rechargeRecordInfoList', form, 'POST');
            this.loadingHide();
            if (ret.success && ret.data) {
                this.list = ret.data.data;
                this.list.map(item => {
                    item.payTypeName = getPayTypeLabel(item.payType).label
                })
                this.total = ret.data.total;
            } else this.$message.error(ret.errors[0].message)
        },
        //格式化车座
        formatCarModel(type) {
            return getSeatModel(type);
        },
        //页码改变
        handleCurrentChange(e) {
            this.form.pageIndex = e;
            this.getData();
        },
        //导出
        async handelExport() {
            this.loadingShow()
            let supplierId = null,
                operatorId = null;
            if (this.selectedOperator.operatorName === this.operatorName)
                operatorId = this.selectedOperator.id
            if (this.selectedSupplier.supplierName === this.supplierName)
                supplierId = this.selectedSupplier.id
            let form = {
                ...this.form,
                operatorId,
                supplierId,
                createTime: this.form.createTime ? +new Date(this.form.createTime) : null
            }
            if (form.dates.length > 0) {
                form.startCreateTime = moment(form.dates[0]).valueOf();
                form.endCreateTime = moment(form.dates[1]).endOf('d').valueOf();
            }
            delete form.dates;
            if (!form.driverName)
                delete form.driverName;
            try {
                const ret = await this.http('/galaxyRechargeRecordApi/rechargeRecordInfoExport', form, 'POST', true, 'blob');
                let d = new Date().getTime();
                let a = document.createElement('a');
                a.download = '服务充值记录' + d + '.xls';
                a.href = window.URL.createObjectURL(ret);
                a.click();
                this.loadingHide();

            } catch (e) {
                console.log(e);
                this.$message.error('导出失败')
                this.loadingHide();
            }
        }
    },
    //监听模版变量
    watch: {}

}
</script>
